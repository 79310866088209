import { Button, ProgressSpinner, Typography } from "@bbnpm/bb-ui-framework";
import { useEffect, useRef, useState } from 'react';
import './landing.min.css';

const Landing = () => {
    const SITE_ADDRESS = process.env.PUBLIC_URL + '/';
    const [logInClicked, setLogInClicked] = useState(false);

    const buttonRef = useRef()
    useEffect(() => {
        window.addEventListener("keydown", e => {
            if (e.key === "Enter") {
                buttonRef.current.click();
            }
        })

        window.addEventListener('unload', e => {
            if(logInClicked){
                setLogInClicked(false)
            }
        } );
    }, [])

    useEffect(() => {
        if (logInClicked) {
            setTimeout(() => {
                setLogInClicked(false);
            }, 15000);
        }
    }, [logInClicked]);

    return (
        <div className="landing">
            <div>
                <div className="top">
                    <div className='leftBox'>
                        <Typography.LargeTitle>Bloomberg <span>ANYWHERE</span></Typography.LargeTitle>
                        <Typography.SubSectionTitle>Access the Bloomberg Terminal wherever you are.</Typography.SubSectionTitle>
                        <p>Financial professionals need constant access to high-quality news, data and analytics. Bloomberg keeps you connected from virtually anywhere, from any type of device.</p>
                    </div>
                    <div className='rightBox'>
                        <Typography.SectionTitle>Log into your Bloomberg Terminal</Typography.SectionTitle>
                        <p>You will need your B-Unit to log in.</p>
                        {!logInClicked && <Button ref={buttonRef} as="a" kind="primary" href={SITE_ADDRESS + 'login'} onClick={() => setLogInClicked(true)}>Log In</Button>}
                        <div id="redirect_spinner" style={{ display: logInClicked ? "block" : "none" }}>
                            <ProgressSpinner size="small" label="Redirecting..." labelPosition="right" />
                        </div>
                        <span className="horizLine"></span>
                        <p className="link-wrapper">
                            Not a Terminal user?&nbsp;
                            <a href="https://www.bloomberg.com/professional/request-demo/">Request a demo</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className='content'>
                    <span className='leftImg'></span>
                    <div className='rightBox'>
                        <Typography.SectionTitle>Access on Mobile</Typography.SectionTitle>
                        <p>The Bloomberg Professional app keeps you connected to the same market-moving news, data and information you depend on at your desktop Terminal.</p>
                        <p className="dl-links">
                            <strong>Available for: </strong>
                            <a href="https://apps.apple.com/us/app/bloomberg-professional/id407761767?platform=ipad">iPad</a>, <a href="https://apps.apple.com/us/app/bloomberg-professional/id407761767">iPhone</a>, and <a href="https://play.google.com/store/apps/details?id=com.bloomberg.android.anywhere">Android</a>
                        </p>
                        <a href="https://apps.apple.com/us/app/bloomberg-professional/id407761767" className="appstoreBtn"></a>
                        <a href="https://play.google.com/store/apps/details?id=com.bloomberg.android.anywhere" className='playstoreBtn'></a>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Landing;
