import { Stack, Typography } from "@bbnpm/bb-ui-framework";
import { useEffect, useState } from "react";
import "./help.min.css";

const Help = () => {
    const SITE_ADDRESS = process.env.PUBLIC_URL + '/';
    const [selected, setSelected] = useState();

    useEffect(() => {
        const links = document.querySelectorAll(".page-nav li");
        links.forEach(link => link.classList.remove("selected"));
        selected && selected.classList.add("selected");
    }, [selected]);

    // subnav
    useEffect(() => {
        const mainContent = document.querySelector(".main-content");
        const links = document.querySelectorAll(".page-nav li");
        setSelected(links[0]);

        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener("click", e => {
                const scrollToElm = document.getElementById(e.currentTarget.getAttribute("linkto"));

                mainContent.scrollTo({
                    top: scrollToElm.offsetTop - 60 - 10, // nav height = 60, 10 = same space from header as subnav
                    behavior: "smooth"
                });
            });
        }

        const currRoute = window.location.href;
        const currSection = currRoute.split("#")[1];
        currSection && document.querySelector(`[linkto=${currSection}]`).click();
    }, []);

    // intersection observer
    useEffect(() => {
        let scrollDirection = "down";
        let prevYPosition = 0;
        const links = document.querySelectorAll(".page-nav li");
        const header = document.querySelector("header.bbui-titlebar");
        const targets = document.querySelectorAll(".help-section-title");
        targets.forEach((target, idx) => target["data-id"] = idx);

        const options = {
            root: document.querySelector("#root"),
            rootMargin: `${(header.offsetHeight + targets[0].offsetHeight * 2) * -1}px`,
            threshold: 0 // when out of view
        };

        const setScrollDirection = () => {
            const scrollRoot = document.querySelector(".bbui-content");
            scrollDirection = scrollRoot.scrollTop >= prevYPosition ? "down" : "up";
            prevYPosition = scrollRoot.scrollTop;
        };

        const handleIntersection = entries => {
            entries.forEach(entry => {
                setScrollDirection();
                const sectionId = entry.target["data-id"];

                if (scrollDirection === "down") {
                    if ((!entry.isIntersecting || sectionId === 0) && // invert conditional because threshold 0
                        entry.boundingClientRect.top < 100) { // ignore events that occur far from top
                        setSelected(links[sectionId]);
                    }
                } else {
                    if (entry.isIntersecting) { // check for visibility when scrolling back up
                        links[sectionId].previousElementSibling && setSelected(links[sectionId].previousElementSibling);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        targets.forEach(target => observer.observe(target));

        return () => observer.disconnect();
    }, []);

    return (<div className="help">
        <div className="help-body">
            <div className="page-nav">
                <ul>
                    <li linkto="section_contact" className="selected">Contact Us</li>
                    <li linkto="section_windows">Windows Help</li>
                    <li linkto="section_mac">Mac Help</li>
                    <li linkto="section_linux">Linux Help</li>
                    <li linkto="section_faq">Frequently Asked Questions</li>
                    <li linkto="section_bunit">B-Unit enrollment</li>
                    <li linkto="section_files">Accessing Local Files</li>
                    <li linkto="section_excel">Bloomberg Excel Add-In</li>
                    <a id="downloads" href="/downloads" target="_blank"><li>Downloads</li></a>
                </ul>
            </div>
            <div className="content">
                <Typography.PageTitle className="page-title">BBA Help</Typography.PageTitle>
                <div id="section_contact" className="help-section">
                    <div className="help-section-title">Contact us</div>
                    <div className="help-section-content contact-section">
                        <div className="group sub_section_group">
                            Not a Terminal user? <a className="font-medium" href="https://www.bloomberg.com/professional/contact-menu/?utm_source=bba-login&amp;bbgsum=DG-WS-PROF-bba-login" target="_blank" rel="noreferrer">Request a demo.</a>
                        </div>

                        <div className="group sub_section_group">
                            <strong>The Americas</strong>
                            <div>+1 212 318 2000</div>
                        </div>

                        <div className="group sub_section_group">
                            <strong>Europe, Middle East, &amp; Africa</strong>
                            <div>+44 20 7330 7500</div>
                        </div>

                        <div className="group sub_section_group">
                            <strong>Asia Pacific</strong>
                            <div>+65 6212 1000</div>
                        </div>

                        <div className="group">
                            <a href="https://www.bloomberg.com/faq/question/how-can-i-contact-bloomberg-for-support">See complete list</a>
                        </div>
                    </div>
                </div>


                <div id="section_windows" className="help-section">
                    <div className="help-section-title">Windows</div>
                    <div className="help-section-content">
                    <h1>System Requirements</h1>
                        <div className="sub-section-content">
                            <div className="group sub-section-group">
                                <div>Windows 10 or later, Server 2016 or later</div>
                                <div>Citrix Workspace App, which can be installed <a href="/downloads" target="_blank">here</a></div>
                            </div>
                        </div>
                        <h1>Accessing files on your local computer</h1>
                        <div className="group question-pair">
                            <h2 id="drive_access_windows">How do I access files on my Windows PC?</h2>
                            <div>
                                <ol className="numeric-list">
                                    <li>
                                        <span>Locate and then right-click on the Citrix Receiver icon in your System Tray.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image001.png"} alt="" /></div>
                                        <div>
                                            <ol className="alpha-list">
                                                <li>
                                                    <span>Note: You may need to click the arrow near the date and time in the System Tray to locate the Citrix Receiver icon..</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image003.png"} alt="" /></div>
                                                </li>
                                            </ol>
                                        </div>
                                    </li>

                                    <li>
                                        <span>Allow access to your computer's file system by using the Citrix Connection Center</span>

                                        <div>
                                            <ol className="alpha-list">
                                                <li>
                                                    <span>From the Citrix Connection Center Dialog Box, click on the server to which you are connected.</span>
                                                    <ul><li>The name of the server usually begins with OBBBA or NJBBA.  In this example the server name is NJBBA2-101-026</li></ul>
                                                </li>

                                                <li>
                                                    <span>After selecting the server name then select the Preferences icon.</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image005.png"} alt="" /></div>
                                                </li>

                                                <li>
                                                    <span>Select the appropiate file level access to your computer's file system.</span>
                                                    <ul><li className="text_red">Note that "Read and Write" grants permission to save files to your computer’s file system.</li></ul>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image007.png"} alt="" /></div>
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <h1> Bloomberg Software Installation for Windows</h1>
                        <div className="sub-section-content">
                        <a className="white-link underline-link" href="https://www.bloomberg.com/professional/downloads/">Download</a> and install the full Windows version of the Bloomberg Terminal on your PC.
                        </div>
                        <h1>Issues Launching with Internet Explorer</h1>
                        <div>If you are having trouble launching Bloomberg Anywhere from Internet Explorer, try one of the following:</div>
                        <div className="group">
                            <h2>Set Bloomberg Anywhere as a trusted site</h2>
                            <div>Tools - Internet Options - Security Tab - Trusted Sites - Sites</div>
                            <div>Add https://bba.bloomberg.net</div>
                        </div>
                        <div className="group">
                            <h2>Enable scripting of ActiveX controls</h2>
                            <div>Tools - Internet Options - Security Tab - Internet Zone - Custom Level</div>
                            <div>Set "Script ActiveX controls marked safe for scripting" to "Enable" or "Prompt"</div>
                        </div>
                    </div>
                </div>

                <div id="section_mac" className="help-section">
                    <div className="help-section-title">Mac</div>
                    <div className="help-section-content">
                        <h1>System Requirements</h1>
                        <div className="sub-section-content">
                            <div className="group sub-section-group">
                                <div>MacOS 11 (Big Sur) or later</div>
                                <div>Citrix Workspace App, which can be installed <a href="/downloads" target="_blank">here</a></div>
                            </div>
                        </div>
                        <div className="group question-pair">
                            <h2 id="drive_access_mac">How do I access files on my Mac?</h2>
                            <div>
                                <div>
                                    <p className="text_red">NOTE: Changes to your local drive access permissions will not take effect when they are made while you are logged into BBA.  Changes will only take effect on logons AFTER you have made a change to your local drive access permissions</p>
                                </div>
                                <ol className="numeric-list">
                                    <li>
                                        <span>Ensure that you are logged out of Bloomberg Anywhere.  Once logged out, locate the Citrix Receiver icon on the menu bar.  Right click on the Citrix Receiver icon and select Preferences.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image001-mac.png"} alt="" /></div>
                                    </li>

                                    <li>
                                        <span>Select the File Access icon and configure the appropriate access to your drives.  Select “Read and Write” to enable the ability to save files to your hard drive.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image071-mac.png"} alt="" /></div>
                                    </li>
                                    <li>
                                        <span>Once logged in to Bloomberg Anywhere you can now access the drives on your Mac.  This can be confirmed by running PFM and selecting the Upload File option.  On the left side below Folders, expand the Shared Drives option.  The Mac client drives will appear as \\Client\Drive letter.  You can now navigate into the Mac client drives to locate a file to upload.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image005-mac.png"} alt="" /></div>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <h1>Configuring Safari</h1>
                        <div className="group">Click on Safari drop down menu on the top left of the screen and scroll down to Preferences</div>
                        <div className="group">Under the General tab, check if “Open safe files after downloading” is checked</div>

                        <div className="sub-section confirm-checked">
                            <h2>Click on the Security tab and make sure the following is checked:</h2>
                            <div className="sub-section-content">
                                <div className="group sub-section-group">
                                    <div>Enable JavaScript</div>
                                </div>
                            </div>
                        </div>

                        <div className="group">Make sure that "Block pop-up windows" is unchecked</div>
                        <div className="group">Under the Privacy Tab, make sure that Safari browser is configured to accept cookies. Uncheck the “Never” option and check the "Only from sites you navigate to"</div>
                        <div className="group">From here the browser should be configured to allow Bloomberg Anywhere to work</div>

                        <h1>Keyboard Mapping</h1>
                        <div>Note: Keyboard Mapping may vary depending on the type of Mac Keyboard you have. The following are suggested settings that should work with most Mac Keyboards</div>

                        <div className="group">
                            <h2>Keyboard Setup</h2>
                            <div>To setup, right click on the Bloomberg Screen and choose “Terminal Defaults”. You can also bring this menu up by hitting the Alt + D keys. Select “Desktop2” as the Keyboard Type for Mac. This is best option and is suitable for both desktop and notebook Macs.</div>
                        </div>

                        <div className="group">
                            <h2>Function Keys</h2>
                            <div>The F1-F7 keys will work by default. Remember to hold down the Fn key before hitting the F keys. For example: Fn+F2 = GOVT</div>
                            <div className="sub-section">
                                <div>To use the F8-F12 keys, you will need to change some system settings as follows:</div>
                                <div className="sub-section-content">
                                    <div className="group sub-section-group">In Mac OS X 10.5 (Leopard), and 10.6 (Snow Leopard) please go to the Apple icon drop down menu on the top left corner of your Mac and select the option for “System Preferences”. Next, select “Expose &amp; Spaces”. Ensure there is no key selected within the Expose section. (Click on the drop down arrows and select a “ – “). Do the same for F8 within Spaces.</div>
                                    <div className="group sub-section-group">In Mac OS X 10.7(Lion), please go to the Apple icon drop down menu on the top left corner of your Mac and select “System Preferences”. Click on the “Keyboard” icon, select the “Keyboard Shortcuts” tab, highlight “Mission Control” and uncheck ALL boxes to disable the built-in Mac functions for F8-F12.</div>
                                </div>
                            </div>
                        </div>

                        <div className="sub-section">
                            <div>The "fn" key is always on the bottom left corner. Here are some other useful shortcuts.</div>
                            <div className="sub-section-content">
                                <div className="sub-section-group">
                                    <div>fn+end (right arrow) = Menu</div>
                                    <div>fn+page (up arrow) = Page Back</div>
                                    <div>fn+page (down arrow) = Page Forward</div>
                                </div>
                            </div>
                        </div>

                        <div className="group">Apple keyboard shortcuts may vary depending on the hardware. If you are unable to find a shortcut for a Bloomberg key, you can create a toolbar button (colored keys at the top of your Bloomberg Panel).  Press the Apple key (command)+ ALT + G, to see all toolbar buttons.  Once the Bloomberg toolbar keys appear, highlight the desired key and click on “Add to End of Toolbar” and the selected button will appear on the top of your Bloomberg Panel.</div>
                    </div>
                </div>

                <div id="section_linux" className="help-section">
                    <div className="help-section-title">Linux</div>
                    <div className="help-section-content">
                    <h1>System Requirements</h1>
                        <div className="sub-section-content">
                            <div className="group sub-section-group">
                            <div>Linux kernel version 2.6.29 or later</div>
                            <div>At least 55 MB of free disk space for the installed version of Workspace App and at least 110 MB if you expand the installation package on the disk.</div>
                            <div>At least 1 GB RAM for system-on-a-chip (SoC) devices that use HDX MediaStream Flash Redirection.</div>
                            <div>256 color video display or higher.</div>
                            <div>TCP/IP networking</div>
                            <div>Citrix Workspace App, which can be installed <a href="/downloads" target="_blank">here</a></div>
                            </div>
                            <div className="group sub-section-group">
                                <h5>For the self-service user interface (UI):</h5>
                                <div>
                                    <div>webkit2gtk 2.16.6 or later</div>
                                    <div>libxml2 2.7.8</div>
                                    <div>libxerces-c 3.1</div>
                                </div>
                            </div>
                            <div className="group sub-section-group">
                                <h5>Libraries:</h5>
                                <div>
                                    <div>glibcxx 3.4.25 or later</div>
                                    <div>glibc 2.27 or later</div>
                                    <div>gtk 3</div>
                                    <div>gtk 2.20.1 or later</div>
                                    <div>libcap1 or libcap2</div>
                                    <div>libjson-c (for instrumentation)</div>
                                    <div>X11 or X.Org (Wayland isn't supported)</div>
                                    <div>udev support</div>
                                    <div>ALSA (libasound2)</div>
                                    <div>PulseAudio</div>
                                    <div>UIDialogLib3.so</div>
                                </div>
                            </div>
                            <div className="group sub-section-group">
                                <h5>Codec:</h5>
                                <div>
                                    <div>Vorbis</div>
                                    <div>libraries: Speex</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="section_faq" className="help-section">
                    <div className="help-section-title">Frequently asked questions</div>
                    <div className="help-section-content">
                        <div className="group">
                            <h2>Are there any limitations for a Bloomberg Anywhere user when using the website login as opposed to a full Windows installation?</h2>
                            <div>There are some limitations with using Bloomberg Anywhere via the web such as limited trading system functionality. For a full list, please contact <a className="white-link underline-link" href="https://www.bloomberg.com/professional/customer-support/">Bloomberg Technical Support</a>.</div>
                        </div>

                        <div className="group">
                            <h2>I’m being asked for a password when installing Citrix on my Mac. What password is this?</h2>
                            <div>This is typically your Mac login password, or the password you originally setup for downloads on your Mac. If you have any difficulty with the password, please contact Apple on 1800-MYAPPLE.</div>
                        </div>

                        <div className="group">
                            <h2>Can I install the Bloomberg Software on my Mac?</h2>
                            <div>Unfortunately, there is no version for Mac. If you are running Windows on your Mac, then you will be able to install the software.</div>
                        </div>

                        <div className="group">
                            <h2>If I step away from the computer for some time, my Bloomberg disconnects, why does this happen?</h2>

                            <div>Bloomberg will automatically disconnect after 60 minutes of inactivity.  If you are experiencing disconnect issues before 60 minutes of inactivity, please check the power options on your computer.</div>
                        </div>
                    </div>
                </div>

                <div id="section_bunit" className="help-section">
                    <div className="help-section-title">B-Unit enroll</div>
                    <div className="help-section-content">
                        <h1>Enroll B-Unit App</h1>

                        <div className="group">
                            <Stack direction="row">
                            <div><img src={process.env.PUBLIC_URL + "/img/image090.png"} width="400" alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/img/image091.png"} width="400" alt="" /></div>
                            </Stack>
                            <div><img src={process.env.PUBLIC_URL + "/img/image092.png"} width="400" alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/img/image093.png"} alt="" /></div>
                        </div>
                        <h1>Enroll B-Unit Device</h1>
                        <div className="group">
                            <Stack direction="row">
                            <div><img src={process.env.PUBLIC_URL + "/img/image090.png"} width="400" alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/img/image094.png"} width="400" alt="" /></div>
                            </Stack>
                            <Stack direction="row">
                            <div><img src={process.env.PUBLIC_URL + "/img/image095.png"} width="400" alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/img/image096.png"} width="400" alt="" /></div>
                            </Stack>
                            <Stack direction="row">
                            <div><img src={process.env.PUBLIC_URL + "/img/image097.png"} width="400" alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + "/img/image098.png"} width="400" alt="" /></div>
                            </Stack>
                            <div><img src={process.env.PUBLIC_URL + "/img/image099.png"} width="400" alt="" /></div>
                        </div>
                </div>
                </div>
                <div id="section_files" className="help-section">
                    <div className="help-section-title">Accessing files on your local computer</div>
                    <div className="help-section-content">
                        <div className="group question-pair">
                            <h2 id="drive_access_windows1">How do I access files on my Windows PC?</h2>
                            <div>
                                <ol className="numeric-list">
                                    <li>
                                        <span>Locate and then right-click on the Citrix Receiver icon in your System Tray.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image001.png"} alt="" /></div>
                                        <div>
                                            <ol className="alpha-list">
                                                <li>
                                                    <span>Note: You may need to click the arrow near the date and time in the System Tray to locate the Citrix Receiver icon..</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image003.png"} alt="" /></div>
                                                </li>
                                            </ol>
                                        </div>
                                    </li>

                                    <li>
                                        <span>Allow access to your computer's file system by using the Citrix Connection Center</span>

                                        <div>
                                            <ol className="alpha-list">
                                                <li>
                                                    <span>From the Citrix Connection Center Dialog Box, click on the server to which you are connected.</span>
                                                    <ul><li>The name of the server usually begins with OBBBA or NJBBA.  In this example the server name is NJBBA2-101-026</li></ul>
                                                </li>

                                                <li>
                                                    <span>After selecting the server name then select the Preferences icon.</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image005.png"} alt="" /></div>
                                                </li>

                                                <li>
                                                    <span>Select the appropiate file level access to your computer's file system.</span>
                                                    <ul><li className="text_red">Note that "Read and Write" grants permission to save files to your computer’s file system.</li></ul>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image007.png"} alt="" /></div>
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="drive_access_mac1">How do I access files on my Mac?</h2>
                            <div>
                                <div>
                                    <p className="text_red">NOTE: Changes to your local drive access permissions will not take effect when they are made while you are logged into BBA.  Changes will only take effect on logons AFTER you have made a change to your local drive access permissions</p>
                                </div>
                                <ol className="numeric-list">
                                    <li>
                                        <span>Ensure that you are logged out of Bloomberg Anywhere.  Once logged out, locate the Citrix Receiver icon on the menu bar.  Right click on the Citrix Receiver icon and select Preferences.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image001-mac.png"} alt="" /></div>
                                    </li>

                                    <li>
                                        <span>Select the File Access icon and configure the appropriate access to your drives.  Select “Read and Write” to enable the ability to save files to your hard drive.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image071-mac.png"} alt="" /></div>
                                    </li>
                                    <li>
                                        <span>Once logged in to Bloomberg Anywhere you can now access the drives on your Mac.  This can be confirmed by running PFM and selecting the Upload File option.  On the left side below Folders, expand the Shared Drives option.  The Mac client drives will appear as \\Client\Drive letter.  You can now navigate into the Mac client drives to locate a file to upload.</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image005-mac.png"} alt="" /></div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="group question-pair">
                            <h2 id="drive_access_mac">How do I access files on Citrix?</h2>
                            <div>
                                <ol className="numeric-list">
                                    <li>
                                        <span>Open Citrix Workspace app.</span>
                                    </li>
                                    <li>
                                        <span>Click the account icon in the top‑right corner and click the Accounts option.</span>
                                    </li>
                                    <li>
                                        <span>Go to the “File access” to select a mapped drive</span>
                                    </li>

                                    <li>
                                        <span>Select “Read and Write” or “Ask me each time”</span>
                                    </li>
                                    <li>
                                        <span>Click “Add” to specify the location and select a drive to map to it</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image066.png"} width="800" alt="" /></div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="section_excel" className="help-section">
                    <div className="help-section-title">Using Bloomberg Excel add-In</div>
                    <div className="help-section-content">
                        <div className="group question-pair">
                            <h2 id="api_excel_whatis">What is Bloomberg Excel Add-In with BLOOMBERG ANYWHERE?</h2>
                            <div>Bloomberg Excel Add-In with BLOOMBERG ANYWHERE allows you to use a remote version of Microsoft Excel running on Bloomberg servers. This is a fully functional copy of Microsoft Excel that you can use to interact with Bloomberg API data.</div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_install_locally">Do I need Microsoft Excel to be installed locally?</h2>
                            <div>No, you don’t need to install Microsoft Excel on your computer to use Bloomberg Excel Add-In. The spreadsheets are opened and saved using a remote version of Excel.</div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_new_functionality">What can I do with this new functionality?</h2>
                            <div className="sub-section-content">
                                <ol>
                                    <li><a className="faq_answer_link" href="#api_excel_in_msg">Open a spreadsheet with API data that is attached to MSG</a></li>
                                    <li>Open a spreadsheet stored on your local <a className="faq_answer_link" href="#api_excel">computer</a></li>
                                        <li>Allow Bloomberg Anywhere to access files on your <a className="faq_answer_link" href="#drive_access_windows">Windows</a>,<a className="faq_answer_link" href="#drive_access_mac">Mac</a></li>
                                    <li><a className="faq_answer_link" href="#api_excel_under_function">Open a spreadsheet under a Bloomberg function. For example XLTP</a></li>
                                    <li>Save a spreadsheet to your local <a className="faq_answer_link" href="#api_excel_sheet_in_file">Windows</a> computer or <a className="faq_answer_link" href="#api_excel_sheet_in_file">Mac</a></li>
                                </ol>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_in_msg">How do I open an Excel spreadsheet in Bloomberg Message (MSG)?</h2>
                            <div className="sub-section-content">
                                <ol>
                                    <li>Open the Message with the attached Excel spreadsheet file</li>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/img/image001-excel.png"} alt="" />
                                    </div>
                                    <li>Click on the name of the attached file that you would like to open. In the example below, the name of the attached file is test.xls.</li>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/img/image000-excel.png"} alt="" />
                                    </div>
                                    <li>You can then select to save this file locally or open it within the Citrix Session</li>
                                </ol>
                                <p className="no-margin-bottom">The attached file might take some time to open based on its size.</p>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel">How do I open an Excel spreadsheet that is on my computer?</h2>
                            <div className="sub-section-content">
                                <ol className="numeric-list">
                                    <li>
                                        <span>Ensure that BLOOMBERG ANYWHERE can access files on your computer</span>
                                        <div>
                                            <ul>
                                                <li><a className="faq_answer_link" href="#drive_access_windows1">How to check and enable drive access on a Windows PC</a></li>
                                                <li><a className="faq_answer_link" href="#drive_access_mac1">How to check and enable drive access on a Mac</a></li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li>
                                        <span>Upload the spreadsheet from your computer to BLOOMBERG ANYWHERE</span>
                                        <div>
                                            <ol className="alpha-list">
                                                <li>From a BLOOMBERG ANYWHERE window, run FILE &lt;GO&gt;</li>
                                                <li>
                                                    <span>Click on the Upload File button</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image005-excel.png"} alt="" /></div>
                                                </li>
                                                <li>
                                                    <span>Expand the Shared Drives folder</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image007-excel.png"} alt="" /></div>
                                                </li>
                                                <li>
                                                    <span>Expand the relevant folder for the drive on which the file that you would like to upload is located</span>
                                                    <div><ul><li>For example, expand \\Client\C$ if you would like to browse to your C:\</li></ul></div>
                                                </li>

                                                <li>Browse to the location of the spreadsheet</li>
                                                <li>Select the spreadsheet that you would like to Open</li>
                                                <li>
                                                    <span>Click on the Upload file button</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image009.png"} alt="" /></div>
                                                </li>

                                                <li>
                                                    <span>Upon the file upload completion, you should get the message “Document *.* upload complete” as shown below</span>
                                                    <div><img src={process.env.PUBLIC_URL + "/img/image011.png"} alt="" /></div>
                                                </li>
                                                <li>Open the uploaded file from FILE &lt;GO&gt; to use it within Bloomberg Anywhere</li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_under_function">How do I open a spreadsheet under a Bloomberg function, for example XLTP &lt;GO&gt;?</h2>
                            <div>
                                <p className="no-margin-top">You can open up a Spreadsheet from under a Bloomberg function. Just one example of doing so is by using XLTP &lt;GO&gt;</p>
                                <div>
                                    <ol className="numeric-list">
                                        <li>Run XLTP &lt;GO&gt;</li>
                                        <li>
                                            <span>Click on the Open button to the lower right hand corner of the screen to open up a spreadsheet</span>
                                            <div><img src={process.env.PUBLIC_URL + "/img/image015.png"} alt="" /></div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_sheet_in_file">How do I open a spreadsheet in FILE/PFM &lt;GO&gt; from within the remote Excel?</h2>
                            <div>
                                <ol className="numeric-list">
                                    <li>From within the remote Excel, click on the Bloomberg tab</li>
                                    <li>Click on the File Manager button</li>
                                    <li>
                                        <span>Select the Open option</span>x
                                        <div><img src={process.env.PUBLIC_URL + "/img/image022.png"} alt="" /></div>
                                    </li>
                                    <li>
                                        <span>From within the Bloomberg File Manager, select the spreadsheet that you would like to open</span>
                                        <div><img src={process.env.PUBLIC_URL + "/img/image024.png"} alt="" /></div>
                                    </li>
                                    <li>
                                        <span>Click on the Open button</span>
                                        <p className="no-margin-top">The file will be downloaded then opened in the Remote Excel</p>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div className="group question-pair">
                            <h2 id="api_excel_using_remote">How can I tell whether I am using remote Excel on BLOOMBERG ANYWHERE?</h2>
                            <div>
                            <p>One of the ways to tell whether you are likely using the remote version of Excel is by checking to see if the author is AgentUser </p>
                                <div><img src={process.env.PUBLIC_URL + "/img/image036.png"} alt="" /></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div >);
}

export default Help;
